'use strict'

export default function (): void {
  const resultsEl = document.getElementById('results')
  const loadingEl = document.getElementById('results-loading')

  if (!resultsEl || !loadingEl) {
    return
  }

  const scrollToEl = document.getElementById('loading-scroll-to') ?? document.body

  resultsEl.addEventListener('loading.show', () => {
    scrollToEl.scrollIntoView({
      block: 'start',
      inline: 'nearest',
      behavior: 'smooth',
    })

    resultsEl.classList.add('results--hidden')
    loadingEl.classList.add('loading--visible')
  })

  window.addEventListener('pageshow', (event) => {
    if (event.persisted) {
      // Hide the loader when the page is fetched from bfcache.
      loadingEl.classList.remove('loading--visible')
      resultsEl.classList.remove('results--hidden')
    }
  })
}
