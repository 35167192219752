// we need to maintain a fix order of vendors that will be called or the build will produce an error (as we have multiple entry points)
import 'assets/core/js/module/calendarManager'
import 'assets/core/js/module/sliderManager'
import 'assets/core/js/module/selectManager'

import { initModal } from 'assets/core/js/module/paymentFacilities'

import dataLayer from 'assets/core/js/page/search/dataLayer'
import imagesSliders from 'assets/themes/campings/js/page/search/imagesSliders'
import budgetSlider from 'assets/themes/campings/js/page/search/budgetSlider'
import openProductPageLinks from 'assets/themes/campings/js/page/search/openProductPageLinks'
import sorting from 'assets/themes/campings/js/page/search/sorting'
import blocksToggle from 'assets/themes/campings/js/page/search/blocksToggle'
import loading from 'assets/themes/campings/js/page/search/loading'
import filters from 'assets/themes/campings/js/page/search/filters'
import recap from 'assets/themes/campings/js/page/search/recap'
import reminder from 'assets/themes/campings/js/page/search/reminder'
import map from 'assets/themes/campings/js/page/search/map'
import bookingReward from 'assets/themes/campings/js/page/search/bookingReward'
import productFavorite from 'assets/core/js/page/search/product/favorite'

import 'nouislider/dist/nouislider.min.css'

document.addEventListener('DOMContentLoaded', function () {
  initModal()

  dataLayer()
  imagesSliders.init()

  budgetSlider()
  openProductPageLinks()
  sorting()
  blocksToggle()
  loading()
  filters()
  recap()
  reminder()
  map()
  productFavorite()
  bookingReward()
})
