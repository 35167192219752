'use strict'

import submitFilterForm from './submitFilterForm'

export default function (): void {
  document.querySelectorAll<HTMLInputElement>('.filter__block input[type="checkbox"]').forEach((el) => {
    el.addEventListener('change', submitFilterForm)

    const topFilterTargetId = el.getAttribute('data-top-filter-target') as string

    const topFilter = document.querySelector<HTMLInputElement>(
      `.filter__block[data-filter="top"] input[type="checkbox"][data-top-filter-target="${el.id}"]`
    )
    const topFilterTarget = <HTMLInputElement | null>document.getElementById(topFilterTargetId)

    if (topFilterTarget) {
      el.addEventListener('click', () => {
        topFilterTarget.checked = el.checked
      })
    }

    if (topFilter) {
      el.addEventListener('click', () => {
        topFilter.checked = el.checked
      })
    }
  })

  document.querySelectorAll<HTMLElement>('.properties-category__see-more').forEach(function (el) {
    el.addEventListener('click', function (e) {
      e.preventDefault()
      if (el.parentNode) {
        const ul = el.parentNode.querySelector<HTMLElement>('ul')
        if (ul) {
          if (ul.classList.contains('list__choices__display-all')) {
            ul.classList.remove('list__choices__display-all')
          } else {
            ul.classList.add('list__choices__display-all')
          }
        }
      }
    })
  })
}
