'use strict'

import * as coreCommon from 'assets/core/js/common'

import noUiSlider, { API } from 'nouislider'
import submitFilterForm from './submitFilterForm'

export default function (): void {
  const sliderStep = 50
  const gapBetweenHandles = 250

  const parentEl = document.querySelector('.filter__block[data-filter="budgets"]')

  if (parentEl) {
    let budgetRangesTemp: string[] = []
    document.querySelectorAll<HTMLInputElement>('.filter__block[data-filter="budgets"] input[type="checkbox"]').forEach(function (el) {
      if (el.value !== '') {
        const values = el.value.split(':')

        budgetRangesTemp = budgetRangesTemp.concat(values.filter((val) => (budgetRangesTemp.indexOf(val) === -1 ? val : false)))
      }
    })
    const budgetRanges: number[] = budgetRangesTemp.map((val) => Number(val))

    const ranges = {
      min: [0, sliderStep],
      max: [budgetRanges[budgetRanges.length - 1], sliderStep],
    }

    const formEl = document.getElementById('filter-offers') as HTMLFormElement

    parentEl.removeChild(parentEl.querySelector('.list__wrapper') as HTMLElement)

    const inputEl = document.createElement('input')
    inputEl.id = 'filter_offers_budgets_0'
    inputEl.type = 'hidden'
    inputEl.name = 'filter_offers[budgets][]'
    if (parentEl.hasAttribute('data-selection')) {
      inputEl.value = parentEl.getAttribute('data-selection') as string
      formEl.appendChild(inputEl)
    }

    const div = document.createElement('div')
    div.className = 'budget-slider'
    const sliderEl = parentEl.appendChild(div)

    let start = [budgetRanges[0], budgetRanges[budgetRanges.length - 1]]

    if (parentEl.hasAttribute('data-selection')) {
      const selection: string[] = (parentEl.getAttribute('data-selection') as string).split(':')

      if (selection[0] && selection[1]) {
        start = [parseInt(selection[0], 10), parseInt(selection[1], 10)]
      }
    }

    const sliderConfig = {
      range: ranges,
      tooltips: [true, true],
      start,
      connect: true,
      format: {
        to: function (value: number) {
          return coreCommon.formatCurrency(value, undefined, true)
        },
        from: function (value: string) {
          return Number(value)
        },
      },
    }

    // @ts-ignore
    noUiSlider.create(sliderEl, sliderConfig)

    sliderEl.querySelectorAll('.noUi-handle').forEach((el) => {
      el.classList.add('noUi-handle-initial-position')
    })

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    sliderEl.noUiSlider.on('slide', function (values, handle, unencoded: number[]) {
      if (unencoded[0] && unencoded[1] && unencoded[1] < unencoded[0] + gapBetweenHandles) {
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
        sliderEl.noUiSlider.set([unencoded[0], unencoded[0] + gapBetweenHandles])
      }
    })

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    sliderEl.noUiSlider.on('change.one', (values, handle, unencoded: number[]) => {
      if (!document.getElementById('filter_offers_budgets_0')) {
        formEl.appendChild(inputEl)
      }

      ;(document.getElementById('filter_offers_budgets_0') as HTMLInputElement).value = `${Math.round(unencoded[0] as number)}:${Math.round(
        unencoded[1] as number
      )}`

      submitFilterForm()
    })

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    sliderEl.noUiSlider.on('slide', (values, handle: number, unencoded, tap, positions: number[], noUiSlider: API) => {
      const target = noUiSlider.target
      const handleEl = target.querySelector(`.noUi-handle-${handle === 1 ? 'upper' : 'lower'}`)
      const position = positions[handle]

      if (!handleEl || !position) {
        return
      }

      if ((handle === 0 && position >= 25) || (handle === 1 && position <= 75)) {
        handleEl.classList.remove('noUi-handle-initial-position')
      } else {
        handleEl.classList.add('noUi-handle-initial-position')
      }
    })
  }
}
