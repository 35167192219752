'use strict'

import SliderManager from 'assets/core/js/module/sliderManager'

export default function (): void {
  const container = document.getElementById('filter-reminder')

  if (container === null) {
    return
  }

  const reminders = container.querySelectorAll('.swiper-slide.filter__reminder')

  if (reminders.length === 0) {
    return
  }

  container.querySelectorAll('.swiper-slide .filter__reminder-reset').forEach((button) => {
    button.addEventListener('click', (e) => {
      e.preventDefault()
      const id = (<HTMLButtonElement>e.target).dataset.id
      const action = (<HTMLButtonElement>e.target).dataset.action
      if (id && document.getElementById(id)) {
        if (action === 'click') {
          ;(<HTMLInputElement>document.getElementById(id)).click()
        } else if (action === 'nullify') {
          ;(<HTMLInputElement>document.getElementById(id)).value = ''
        }
      }

      if (document.getElementById('filter_offers_page')) {
        ;(<HTMLInputElement>document.getElementById('filter_offers_page')).value = '1'
      }
      if (document.getElementById('filter_offers_filter')) {
        ;(<HTMLInputElement>document.getElementById('filter_offers_filter')).click()
      }
    })
  })

  SliderManager.initSlider('#filter-reminder .swiper', {
    slidesPerView: 'auto',
    spaceBetween: 8,
    navigation: {
      nextEl: '.button-next',
      prevEl: '.button-prev',
    },
  })
}
