'use strict'

import * as LayerManager from 'assets/core/js/module/layerManager'
import ElementPropertiesManager from 'assets/core/js/module/elementPropertiesManager'
import SidePanel from '@campings-group/design-system/src/design/objects/sidePanel/twig/assets'
import type { SidePanelConfig as BaseSidePanelConfig } from '@campings-group/design-system/src/design/objects/sidePanel/twig/assets'

export type SidePanelType = SidePanel
export type SidePanelConfig = BaseSidePanelConfig

export default (element: string | HTMLElement, config?: SidePanelConfig): SidePanel => {
  if (!config) {
    config = {}
  }

  let onOpen: SidePanelConfig['onOpen'] | null = null
  let onClose: SidePanelConfig['onClose'] | null = null

  if (config?.onOpen) {
    onOpen = config.onOpen
  }

  config.onOpen = (sidePanel) => {
    LayerManager.onOpen(sidePanel.getElement())
    onOpen && onOpen(sidePanel)
  }

  if (config?.onClose) {
    onClose = config.onClose
  }

  config.onClose = (sidePanel) => {
    LayerManager.onClose(sidePanel.getElement())
    onClose && onClose(sidePanel)
  }

  const sidePanel = new SidePanel(element, config)

  ElementPropertiesManager.addProperty(sidePanel.getElement(), 'sidePanel', sidePanel)

  return sidePanel
}
