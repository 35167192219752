'use strict'

import SelectManager, { Select } from 'assets/core/js/module/selectManager'

export default function (): void {
  const form = document.getElementById('filter-offers') as HTMLFormElement

  const sidebarSortEl = document.querySelector('.sidebar .sort-form select')
  const headerSortEl = document.querySelector('.results__header .sort-form select')

  if (!sidebarSortEl || !headerSortEl) {
    return
  }

  sidebarSortEl.id = 'filter_offers_sortBy'
  headerSortEl.id = 'filter_offers_sortBy_header'

  let sidebarSortSelect: Select | null
  let headerSortSelect: Select | null

  if (sidebarSortEl) {
    sidebarSortSelect = SelectManager.getSelect(sidebarSortEl.id)

    sidebarSortSelect.passedElement.element.addEventListener('change', function () {
      form.submit()
    })
  }

  // @ts-ignore
  if (sidebarSortSelect && headerSortEl) {
    headerSortSelect = SelectManager.getSelect(headerSortEl.id)

    headerSortSelect.passedElement.element.addEventListener('change', function (this: HTMLSelectElement) {
      SelectManager.setValue(sidebarSortSelect?.passedElement.element.id as string, this.value)
    })
  }
}
