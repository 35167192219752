'use strict'

import ElementPropertiesManager from 'assets/core/js/module/elementPropertiesManager'

import type SidePanel from '@campings-group/design-system/src/design/objects/sidePanel/twig/assets'

export default function (): void {
  if (document.querySelector('.toggle__button[data-target="filters"]')) {
    document.querySelector('.toggle__button[data-target="filters"]')?.addEventListener('click', function () {
      const sidePanelEl = document.getElementById('search-filters-side-panel')

      if (!sidePanelEl) {
        return
      }

      const sidePanel = ElementPropertiesManager.getProperty<SidePanel>(sidePanelEl, 'sidePanel')

      if (!sidePanel) {
        return
      }

      sidePanel.open()

      sidePanel.getElement()?.querySelector<HTMLElement>('.tab__item[data-target="search"]')?.click()
    })
  }
}
