'use strict'

import ImagesSlider from 'assets/core/js/module/imagesSlider'
import { Swiper } from 'swiper'

export default {
  init: function () {
    const slidersId: string[] = []

    document.querySelectorAll<HTMLElement>('.product__images .swiper:not(.swiper-horizontal)').forEach(function (el) {
      const sliderId = ImagesSlider(el, {
        pagination: {
          el: '.swiper-pagination',
          type: 'custom',
          renderCustom: function (swiper: Swiper, current: number, total: number) {
            return `${current}/${el.hasAttribute('data-total-images') ? (el.getAttribute('data-total-images') as string) : total}`
          },
        },
        spaceBetween: 10,
      })

      if (sliderId) {
        slidersId.push(sliderId)
      }
    })

    return slidersId
  },
}
