'use strict'

import * as LayerManager from 'assets/core/js/module/layerManager'
import ElementPropertiesManager from 'assets/core/js/module/elementPropertiesManager'
import Modal from '@campings-group/design-system/src/design/objects/modal/twig/assets'
import type { ModalConfig as BaseModalConfig } from '@campings-group/design-system/src/design/objects/modal/twig/assets'

export type ModalType = Modal
export type ModalConfig = BaseModalConfig

export default (element: string | HTMLElement, config?: ModalConfig): Modal => {
  if (!config) {
    config = {}
  }

  let onOpen: ModalConfig['onOpen'] | null = null
  let onClose: ModalConfig['onClose'] | null = null

  if (config?.onOpen) {
    onOpen = config.onOpen
  }

  config.onOpen = (modal) => {
    LayerManager.onOpen(modal.getElement())
    onOpen && onOpen(modal)
  }

  if (config?.onClose) {
    onClose = config.onClose
  }

  config.onClose = (modal) => {
    LayerManager.onClose(modal.getElement())
    onClose && onClose(modal)
  }

  const modal = new Modal(element, config)

  ElementPropertiesManager.addProperty(modal.getElement(), 'modal', modal)

  return modal
}
