'use strict'

import dataLayer from 'assets/core/js/module/dataLayer'
import SearchMapModal from '@ui/Search/Map/Modal/component'
import ElementPropertiesManager from 'assets/core/js/module/elementPropertiesManager'

import type { QueryResponse } from 'assets/core/js/module/searchMap'

interface QueryResponseWithGTM extends QueryResponse {
  gtmData: {
    [key: string]: {
      product_data: {
        product_city: string
        product_country: string
        product_department: string
        product_id: number
        product_name: string
        product_ratings_count: number
        product_ratings_score: number
        product_region: string
      }
    }
  }
}

export const openMap = (productId: string): void => {
  const mapEl = document.getElementById('search-map-modal-container')

  if (!mapEl) {
    return
  }

  const map = ElementPropertiesManager.getProperty<SearchMapModal>(mapEl, 'searchMapModal')

  if (!map) {
    mapEl.addEventListener('map-initialized', () => {
      ;(map as unknown as SearchMapModal).map?.openMarker(productId)
    })
  } else {
    mapEl.addEventListener(
      'map-query-done',
      () => {
        map.map?.openMarker(productId)
      },
      { once: true }
    )

    map.map?.openMarker(productId)
  }

  document.getElementById('toggle-map')?.click()
}

export default (): SearchMapModal | undefined => {
  const mapEl = document.getElementById('search-map-modal-container')

  if (!mapEl) {
    return
  }

  let gtmData: QueryResponseWithGTM['gtmData'] = {}

  const map = new SearchMapModal(mapEl, {
    callbacks: {
      onQuery: [
        (map, response: QueryResponseWithGTM) => {
          gtmData = response.gtmData
        },
      ],
      onMove: [
        () => {
          dataLayer.pushInLayer(window.coreGtmId, {
            event: dataLayer.events.SEARCH_MAP_MOVE,
          })
        },
      ],
      onMarkerClick: [
        (map, markerId: string) => {
          dataLayer.pushInLayer(window.coreGtmId, {
            product_data: gtmData[markerId]?.product_data ?? null,
            event: dataLayer.events.SEARCH_MAP_SELECT_PRODUCT,
          })
        },
      ],
      onModalOpen: [
        () => {
          dataLayer.pushInLayer(window.coreGtmId, {
            event: dataLayer.events.SEARCH_MAP_OPEN,
          })
        },
      ],
      onModalClose: [
        () => {
          dataLayer.pushInLayer(window.coreGtmId, {
            event: dataLayer.events.SEARCH_MAP_CLOSE,
          })
        },
      ],
    },
  })

  document.addEventListener('click', (e) => {
    const target = e.target as HTMLElement

    if (target && target.classList.contains('toggle-map-link')) {
      const markerId = target.getAttribute('data-id') as string

      openMap(markerId)
    }
  })

  document.querySelectorAll('.toggle-map__button').forEach((el) => {
    el.addEventListener('click', () => {
      if (map.modal) {
        map.modal.open()

        return
      }

      const modal = map.initModal()

      if (modal) {
        modal.open()
      }
    })
  })
}
