'use strict'

import common from 'assets/themes/campings/js/page/common/index'

export default function (): void {
  const preventOpenClasses = ['toggle-map-link', 'dca-tags__hidden-number', 'dca-tags', 'best-offer__payment-facilities']

  document.querySelectorAll('.result__best-offer').forEach(function (el) {
    el.addEventListener('click', function (this: HTMLElement, e) {
      const target = e.target as HTMLElement

      if (preventOpenClasses.filter((val) => target.classList.contains(val)).length === 0) {
        e.preventDefault()
        e.stopPropagation()

        const closestDcaResult = this.closest('.dca-result') as HTMLElement

        const link = Array.from(this.classList).includes('last-minute')
          ? (closestDcaResult.querySelector('.best-offer__prices span.dca-button')?.getAttribute('data-href') as string)
          : closestDcaResult.querySelector<HTMLLinkElement>('.product__link')?.href
        openLink(link, target)
      }
    })
  })

  document.querySelectorAll('.result__accommodations .accommodation').forEach(function (el) {
    el.addEventListener('click', function (this: HTMLElement, e) {
      const target = e.target as HTMLElement

      if (preventOpenClasses.filter((val) => target.classList.contains(val) || target.closest(`.${val}`) !== null).length === 0) {
        e.preventDefault()
        e.stopPropagation()

        const linkTarget = this.querySelector('.accommodation__name .accommodation__link') as HTMLLinkElement
        let link = linkTarget.getAttribute('data-href') || linkTarget.href
        // The link can be obfuscated (see assets/core/js/module/seoLink.ts).
        if (linkTarget.getAttribute('data-seo-link-href')) {
          link = atob(linkTarget.getAttribute('data-seo-link-href') as string)
        }
        openLink(link, target)
      }
    })
  })

  document.querySelectorAll('.dca-result .result__details').forEach(function (el) {
    el.addEventListener('click', function (this: HTMLElement, e) {
      const target = e.target as HTMLElement

      let classes = preventOpenClasses

      if (!common.isMobile()) {
        classes = [...preventOpenClasses, ...['localisation', 'review__link', 'review__title', 'review__value', 'review__max']]
      }

      if (classes.filter((val) => target.classList.contains(val)).length === 0) {
        e.preventDefault()
        e.stopPropagation()

        const link = this.closest('.dca-result')?.querySelector('.product__link') as HTMLLinkElement
        openLink(link ? link.href : null, target)
      }
    })
  })

  document.querySelectorAll('.dca-result .product__info, .dca-result .product__header').forEach(function (el) {
    el.addEventListener('click', function (this: HTMLElement, e) {
      const target = e.target as HTMLElement

      if (preventOpenClasses.filter((val) => target.classList.contains(val)).length === 0) {
        e.preventDefault()
        e.stopPropagation()

        const link = this.closest('.dca-result')?.querySelector('.product__link') as HTMLLinkElement
        openLink(link ? link.href : null, target)
      }
    })
  })

  document.querySelectorAll('.product__reviews').forEach(function (el) {
    el.addEventListener('click', function (this: HTMLElement, e) {
      const target = e.target as HTMLElement
      if (preventOpenClasses.filter((val) => target.classList.contains(val)).length === 0) {
        e.preventDefault()
        e.stopPropagation()

        const link = this.querySelector('.reviews__campings') as HTMLLinkElement
        let href = null
        if (!common.isMobile() && link) {
          href = link.href
        } else if (link) {
          href = link.href.split('#')[0]
        }
        openLink(href, target)
      }
    })
  })
}

function openLink(url: string | null | undefined, el: HTMLElement): void {
  if (url) {
    if (!common.isMobile()) {
      window.open(url)
    } else {
      document.location.href = url
    }
    const event = new CustomEvent('productPageLink.opened', { detail: { productId: el.closest('.dca-result')?.getAttribute('data-id') } })
    window.dispatchEvent(event)
  }
}
