'use strict'

import * as common from 'assets/core/js/common'

export default function (
  filtersForm: Element,
  inputSelector: string,
  changeCallback?: (() => void) | null,
  clickCallback?: ((el?: Element) => void) | null
): void {
  const moreFiltersEl = document.getElementById('more-filters')
  const recapListEl = document.getElementById('search-filters-recap-list')

  if (recapListEl) {
    recapListEl.addEventListener('click', (e) => {
      const target = e.target as Element

      if (!target) {
        return
      }

      if (target.classList.contains('dca-recap__item') || target.classList.contains('dca-recap__action')) {
        if (clickCallback) {
          clickCallback(target)
        }
      }
    })
  }

  if (moreFiltersEl && recapListEl) {
    const removeFilterFn = function (this: Node): void {
      if (!filtersForm) {
        return
      }

      const parentEl = this.parentNode as HTMLElement
      const submitEl: HTMLButtonElement | null = filtersForm.querySelector('[type="submit"]')

      if (!parentEl || !submitEl) {
        return
      }

      const target = parentEl.getAttribute('data-target')

      if (target) {
        document.getElementById(target)?.click()
        submitEl.click()
      }
    }

    moreFiltersEl.addEventListener('click', function (e) {
      e.preventDefault()

      if (clickCallback) {
        clickCallback(this)
      }
    })

    recapListEl.querySelectorAll('.dca-recap__item-reset').forEach(function (el) {
      el.addEventListener('click', removeFilterFn)
    })

    const itemClone = recapListEl.querySelector('.dca-recap__item')?.cloneNode(true)
    const total = moreFiltersEl.getAttribute('data-total') as string
    let totalFilters = parseInt(total, 10)

    if (filtersForm && inputSelector) {
      filtersForm.querySelectorAll<HTMLInputElement>(inputSelector).forEach(function (el) {
        const filterChangeFn = function (): void {
          if (changeCallback) {
            changeCallback()
          }

          const labelEl = el.nextElementSibling?.querySelector('.filter__label') as HTMLElement
          const label = labelEl.innerText

          if (el.checked) {
            const itemEl = itemClone?.cloneNode(true) as HTMLElement
            itemEl.classList.add('dca-recap__item--selected')
            itemEl.setAttribute('data-target', el.id)
            itemEl.setAttribute('data-types', 'filters')

            const itemValueEl = itemEl.querySelector('.dca-recap__item-value') as HTMLElement
            itemValueEl.setAttribute('data-field', el.value)
            itemValueEl.innerText = label

            const resetEl = document.createElement('span')
            resetEl.className = 'dca-recap__item-reset'

            resetEl.addEventListener('click', removeFilterFn)

            itemEl.appendChild(resetEl)
            recapListEl.appendChild(itemEl)

            totalFilters++
          } else {
            const recapEl = recapListEl.querySelector(`.dca-recap__item[data-target="${el.id}"]`)

            if (recapEl) {
              recapListEl.removeChild(recapEl)
              totalFilters--
            }
          }

          moreFiltersEl.setAttribute('data-total', totalFilters.toString())

          const parts = el.id.split('_')
          const filterCategory = parts[parts.length - 2]
          window.dispatchEvent(new CustomEvent('search.filters.change', { detail: { label, filterCategory } }))
        }

        el.addEventListener('click', filterChangeFn)
      })
    }
  }
}

export function addListenerToUpdateWithSearchFilters(el: Element): void {
  el.querySelectorAll('.dca-recap__item[data-type="search"][data-target]').forEach(function (el) {
    const target = document.getElementById(el.getAttribute('data-target') as string)

    if (target) {
      target.addEventListener('change', function (this: HTMLSelectElement) {
        let value = this.innerText || this.value

        const selectedOption = this.options && this.options[this.selectedIndex]

        if (selectedOption) {
          value = selectedOption.text
        }

        const placeholder = (this.getAttribute('data-placeholder') as string) || (this.getAttribute('placeholder') as string)

        if (this.id === 'checkInDate' && value) {
          value = common.formatDate(value)
        }

        const valueEl = el.querySelector('.dca-recap__item-value')

        if (valueEl) {
          valueEl.innerHTML = value || placeholder
        }

        if (value) {
          el.classList.add('dca-recap__item--selected')
        } else {
          el.classList.remove('dca-recap__item--selected')
        }
      })
    }
  })
}
